import * as React from 'react';
import styled from 'styled-components';
import {graphql} from "gatsby";
import PageContent from '../components/PageContent';
import Layout from '../components/Layout';
import CallToAction from "../components/CallToAction";

const Card  = styled.div`
  .author {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 40px;
  }
`;

export function Testimonials(props) {
  const edges = props.data.allMarkdownRemark.edges;

    return (
    <Layout subPageTitle='Testimonials'>
      <PageContent>
        <h1>Testimonials</h1>
        {edges.map(edge => {
          const { author } = edge.node.frontmatter;
          return (
            <Card key={edge.node.id}>
              <div dangerouslySetInnerHTML={{ __html: edge.node.html }} />
              {author && <div className='author'>- {author}</div>}
            </Card>
          );
        })}
        <br />
        <CallToAction
            to='/services'
            primerText='Find a package that is right for you'
            actionText='Check out our services'
        />
      </PageContent>
    </Layout>
  );
}

export const query = graphql`
query {
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/testimonials/"}}, sort: {fields: frontmatter___order, order: ASC}) {
    edges {
      node {
        html
        id
        frontmatter {
          author
        }
      }
    }
  }
}
`;

export default Testimonials;
